<template>
  <SubNav class="subnav-position" :selected="'details'" :id="id" />
  <main>
    <form v-if="offering" @submit.prevent="handleSave" class="shadow edit-form">
      <div class="form-outline mb-4">
        <label
          >Investment Name (e.g. Sale of Shares, Convertible Note, SAFE, Debt
          Offering)</label
        >
        <input type="text" v-model="offering.name" class="form-control" />
      </div>
      <div class="form-outline mb-4">
        <div v-if="offering.mainURL" class="edit-large-picture">
          <img :src="offering.mainURL" />
        </div>
        <div>
          <label>Upload Main Marketing Image</label>
        </div>
        <div class="error" v-if="errorMainImage">{{ errorMainImage }}</div>
        <div v-if="isMainImagePending">
          <button class="btn btn-secondary" disabled>
            <div class="spinner-border" role="status">
              <span class="visually-hidden">Saving...</span>
            </div>
          </button>
        </div>
        <div v-else>
          <input type="file" @change="handleMainImageChange" />
        </div>
      </div>
      <div class="form-outline mb-4">
        <label>Offering Description</label>
        <textarea
          v-model="offering.description"
          class="form-control long-text"
        ></textarea>
      </div>
      <div class="form-outline mb-4">
        <label>Offering Type</label>
        <div class="pt-2">
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="radio"
              name="offeringType"
              id="offeringEquity"
              v-model="offering.offeringType"
              value="equity"
            />
            <label class="form-check-label" for="offeringEquity">Equity</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="radio"
              name="offeringType"
              id="offeringDebt"
              v-model="offering.offeringType"
              value="debt"
            />
            <label class="form-check-label" for="offeringDebt">Debt</label>
          </div>
        </div>
      </div>
      <div v-if="offering.offeringType == 'debt'">
        <div class="form-outline mb-4">
          <label>Offering Type Description</label>
          <div class="pt-2">
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="radio"
                name="offeringTypeDescription"
                id="offeringConvertibleNote"
                v-model="offering.offeringTypeDescription"
                value="Convertible Note"
              />
              <label class="form-check-label" for="offeringEquity"
                >Convertible Note</label
              >
            </div>
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="radio"
                name="offeringTypeDescription"
                id="offeringSAFE"
                v-model="offering.offeringTypeDescription"
                value="SAFE"
              />
              <label class="form-check-label" for="offeringDebt">SAFE</label>
            </div>
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="radio"
                name="offeringTypeDescription"
                id="offeringSimpleDebt"
                v-model="offering.offeringTypeDescription"
                value="Simple Debt"
              />
              <label class="form-check-label" for="offeringDebt"
                >Simple Debt</label
              >
            </div>
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="radio"
                name="offeringTypeDescription"
                id="offeringRoyaltyBased"
                v-model="offering.offeringTypeDescription"
                value="Royalty-Based Repayment"
              />
              <label class="form-check-label" for="offeringDebt"
                >Royalty-Based Repayment</label
              >
            </div>
          </div>
        </div>
        <div class="form-outline mb-4">
          <label
            >Interest Rate (Interest rate of 0 will be treated as
            'royalty-based')</label
          >
          <input
            type="number"
            step="0.01"
            v-model="offering.interestRate"
            class="form-control"
          />
        </div>
        <div class="form-outline mb-4">
          <label>Maturity Date</label>
          <input
            type="date"
            v-model="offering.maturityDate"
            class="form-control"
          />
        </div>
        <div class="form-outline mb-4">
          <label>Maturity Years</label>
          <input
            type="number"
            step="1"
            v-model="offering.maturityYears"
            class="form-control"
          />
        </div>
      </div>
      <div v-else>
        <div class="form-outline mb-4">
          <label>Offering Type Description</label>
          <div class="pt-2">
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="radio"
                name="offeringTypeDescription"
                id="offeringEquityCommonShares"
                v-model="offering.offeringTypeDescription"
                value="Common Shares"
              />
              <label class="form-check-label" for="offeringEquity"
                >Sale of Common Shares</label
              >
            </div>
            <div class="form-check form-check-inline">
              <input
                class="form-check-input"
                type="radio"
                name="offeringTypeDescription"
                id="offeringEquityPreferredShares"
                v-model="offering.offeringTypeDescription"
                value="Preferred Shares"
              />
              <label class="form-check-label" for="offeringDebt"
                >Sale of Preferred Shares</label
              >
            </div>
          </div>
        </div>
      </div>
      <div class="form-outline mb-4">
        <label>Price Per Share</label>
        <input
          type="number"
          v-model="offering.pricePerShare"
          class="form-control"
        />
      </div>
      <div class="form-outline mb-4">
        <label>Issuer Name</label>
        <input type="text" v-model="offering.issuerName" class="form-control" />
      </div>
      <div class="form-outline mb-4">
        <label>Tax ID</label>
        <input
          type="text"
          v-model="offering.taxId"
          class="form-control"
          @blur="validateTaxId"
        />
        <div class="error">{{ taxIdError }}</div>
      </div>
      <div class="form-outline mb-4">
        <label>State of Formation</label>
        <StateSelector
          :selectedState="offering.stateFormation"
          @select-state="offering.stateFormation = $event"
        ></StateSelector>
      </div>
      <div class="form-outline mb-4">
        <label>Company Founded (Month Year)</label>
        <input
          type="text"
          v-model="offering.companyFounded"
          class="form-control"
        />
      </div>
      <div class="form-outline mb-4">
        <label>Issuer Street Address</label>
        <input
          type="text"
          v-model="offering.issuerStreet"
          class="form-control"
        />
      </div>
      <div class="form-outline mb-4">
        <label>Apartment, Suite or Unit Number</label>
        <input
          type="text"
          v-model="offering.issuerSuite"
          class="form-control"
        />
      </div>
      <div class="form-outline mb-4">
        <label>Issuer City</label>
        <input type="text" v-model="offering.issuerCity" class="form-control" />
      </div>
      <div class="form-outline mb-4">
        <label>Issuer State</label>
        <StateSelector
          :selectedState="offering.issuerState"
          @select-state="offering.issuerState = $event"
        ></StateSelector>
      </div>
      <div class="form-outline mb-4">
        <label>Issuer Zip Code</label>
        <input type="text" v-model="offering.issuerZip" class="form-control" />
      </div>
      <div class="form-outline mb-4">
        <label>Contact Name</label>
        <input
          type="text"
          v-model="offering.contactName"
          class="form-control"
        />
      </div>
      <div class="form-outline mb-4">
        <label>Contact Email</label>
        <input
          type="text"
          v-model="offering.contactEmail"
          class="form-control"
          @blur="validateEmail"
        />
        <div class="error">{{ emailError }}</div>
      </div>
      <div class="form-outline mb-4">
        <label>Contact Phone Number</label>
        <input
          type="text"
          v-model="offering.contactPhone"
          class="form-control"
        />
      </div>
      <div class="form-outline mb-4">
        <label>Target Raise Amount ($5000 - $5,000,000)</label>
        <input
          type="number"
          min="5000"
          max="5000000"
          step="1"
          v-model="offering.raiseAmount"
          class="form-control"
        />
      </div>
      <div class="form-outline mb-4">
        <label>Minimum Raise Amount ($5000, $5,000000)</label>
        <input
          type="number"
          min="5000"
          max="5000000"
          step="1"
          v-model="offering.raiseAmountMin"
          class="form-control"
        />
      </div>
      <div class="form-outline mb-4">
        <label>Maximum Raise Amount ($5000 - $5,000,000)</label>
        <input
          type="number"
          min="5000"
          max="5000000"
          step="1"
          v-model="offering.raiseAmountMax"
          class="form-control"
        />
      </div>
      <div class="form-outline mb-4">
        <label>Minimum Investment ($100 - $1,000,000)</label>
        <input
          type="number"
          min="100"
          max="1000000"
          step="1"
          v-model="offering.minInvestment"
          class="form-control"
        />
      </div>
      <div class="form-outline mb-4">
        <label>Maximum Investment ($100 - $5,000,000)</label>
        <input
          type="number"
          min="100"
          max="5000000"
          step="1"
          v-model="offering.maxInvestment"
          class="form-control"
        />
      </div>
      <div class="form-outline mb-4">
        <label>Company Pre-Money Valuation</label>
        <input
          type="number"
          v-model="offering.preValuation"
          class="form-control"
        />
      </div>
      <div class="form-outline mb-4">
        <label
          >Closing date of offering (deadline to reach the target amount)</label
        >
        <input
          type="date"
          v-model="offering.offeringCloses"
          class="form-control"
        />
      </div>
      <div class="form-outline mb-4">
        <div v-if="offering.logoURLThumb">
          <img :src="offering.logoURLThumb" />
        </div>
        <div>
          <label>Upload Offering Logo</label>
        </div>
        <div v-if="errorLogoImage" class="error">{{ errorLogoImage }}</div>
        <div v-if="isLogoImagePending">
          <button class="btn btn-secondary" disabled>
            <div class="spinner-border" role="status">
              <span class="visually-hidden">Saving...</span>
            </div>
          </button>
        </div>
        <div v-else><input type="file" @change="handleLogoChange" /></div>
      </div>
      <div class="form-outline mb-4">
        <label>Company Website (https://www.company.com)</label>
        <input type="text" v-model="offering.website" class="form-control" />
      </div>
      <div class="form-outline mb-4">
        <label>LinkedIn (https://www.instagram.com/company)</label>
        <input type="text" v-model="offering.linkedIn" class="form-control" />
      </div>
      <div class="form-outline mb-4">
        <label>Facebook (https://www.facebook.com/company)</label>
        <input type="text" v-model="offering.facebook" class="form-control" />
      </div>
      <div class="form-outline mb-4">
        <label>Instagram (https://www.instagram.com/company/)</label>
        <input type="text" v-model="offering.instagram" class="form-control" />
      </div>
      <div class="form-outline mb-4">
        <label>Twitter (https://twitter.com/company)</label>
        <input type="text" v-model="offering.twitter" class="form-control" />
      </div>
      <!-- <div class="form-outline mb-4">
        <label>TikTok (https://www.tiktok.com/@company)</label>
        <input type="text" v-model="offering.tiktok" class="form-control" />
      </div> -->

      <!-- <AutoComplete
        :suggestions="offeringTags"
        @select-tag="addTag($event)"
      ></AutoComplete> -->
      <div class="form-outline mb-4">
        <label>Tags (hit enter to add a tag)</label>
        <input
          type="text"
          v-model="tag"
          class="form-control"
          @keydown.enter.prevent="handleTag"
        />
        <div v-for="tag in offering.tags" :key="tag" class="pill btn">
          <span @click="deleteTag(tag)">#{{ tag }}</span>
        </div>
      </div>
      <div class="form-outline mb-4">
        <label>Accepts the following types of investors:</label>
        <div class="form-check">
          <input
            class="form-check-input"
            type="checkbox"
            id="allowAccreditedInvestors"
            v-model="offering.allowAccreditedInvestors"
          />
          <label class="form-check-label" for="flexCheckChecked">
            Allow accredited investors?
          </label>
        </div>
        <div class="form-check">
          <input
            class="form-check-input"
            type="checkbox"
            id="allowNonAccreditedInvestors"
            v-model="offering.allowNonAccreditedInvestors"
          />
          <label class="form-check-label" for="flexCheckChecked">
            Allow non-accredited investors?
          </label>
        </div>
        <div class="form-check">
          <input
            class="form-check-input"
            type="checkbox"
            id="allowUSInvestors"
            v-model="offering.allowUSInvestors"
          />
          <label class="form-check-label" for="flexCheckChecked">
            Allow US investors?
          </label>
        </div>
        <div class="form-check">
          <input
            class="form-check-input"
            type="checkbox"
            id="allowNonUSInvestors"
            v-model="offering.allowNonUSInvestors"
          />
          <label class="form-check-label" for="flexCheckChecked">
            Allow non-US investors?
          </label>
        </div>
      </div>
      <div v-if="invoice" class="form-outline mb-4">
        <div v-if="invoice.stripeInvoiceStatus == 'paid'">
          <label>
            Your publishing invoice for ${{ offering.publishingPayment }} has
            been paid.&nbsp;
          </label>
          <a :href="invoice.stripeInvoiceUrl" target="_blank">View Receipt</a>
        </div>
        <div v-else>
          <label>
            You have a balance of ${{ offering.publishingPayment }} due for
            publishing.&nbsp;
          </label>
          <a :href="invoice.stripeInvoiceUrl" target="_blank">Pay Now</a>
        </div>
      </div>
      <div class="error">{{ formError }}</div>
      <div v-if="!isPending" class="row text-center">
        <div class="col">
          <button class="btn btn-primary">Save</button>
        </div>
        <div class="col">
          <router-link :to="{ name: 'Offerings' }" class="btn btn-secondary">
            Cancel
          </router-link>
        </div>

        <div v-if="!offering.published" class="col">
          <div v-if="offering.requestPublish">
            <button class="btn" disabled>
              Publication Pending
            </button>
          </div>
          <div v-else>
            <span
              v-if="isPublishable"
              class="btn btn-primary"
              data-bs-toggle="modal"
              data-bs-target="#confirmPublishModal"
            >
              Request Publication
            </span>
            <button v-else class="btn" disabled>
              Offering Not Ready
            </button>
          </div>
        </div>
        <div v-if="!offering.published" class="col">
          <span
            class="btn btn-link text-danger"
            data-bs-toggle="modal"
            data-bs-target="#confirmDeleteModal"
          >
            Delete Offering
          </span>
        </div>

        <div v-if="offering.published" class="col">
          Offering is Published.
        </div>
      </div>
      <div v-else>
        <button class="btn btn-secondary" disabled>
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Saving...</span>
          </div>
        </button>
      </div>
    </form>
    <ModalDelete title="Delete Offering?" @confirm-delete="handleDelete">
      Are you sure you want to permanently delete this offering?
    </ModalDelete>
    <ModalPublish title="Request Publication?" @confirm-publish="handlePublish">
      Are you sure you want to submit this offering for review? You can not make
      funding changes to this offering once it is published.
    </ModalPublish>
  </main>
  <Footer v-if="offering" />
</template>

<script>
import Footer from "@/components/navigation/Footer.vue";
import { useRouter } from "vue-router";
import ModalDelete from "@/components/modals/ModalDelete.vue";
import ModalPublish from "@/components/modals/ModalPublish.vue";
import useStorage from "@/composables/useStorage";
import getDocument from "@/composables/getDocument";
import useDocument from "@/composables/useDocument";
import { timestamp } from "@/firebase/config";
import { ref } from "@vue/reactivity";
import SubNav from "@/components/navigation/SubNav.vue";
import { computed } from "@vue/runtime-core";
import StateSelector from "@/components/StateSelector.vue";
import useCollection from "@/composables/useCollection";
import getUser from "@/composables/getUser";
// import AutoComplete from "@/components/tags/AutoComplete.vue";

export default {
  props: ["id"],
  components: {
    SubNav,
    ModalDelete,
    ModalPublish,
    StateSelector,
    // AutoComplete,
    Footer
  },
  setup(props) {
    const tag = ref("");
    const requestPublish = ref(false);
    const taxIdError = ref(null);
    const emailError = ref(null);
    const validTaxId = ref(/(^\d{9})|(^\d{3}-\d{2}-\d{4}$)|(^[1-9]\d?-\d{7}$)/);
    const selected = ref("Details");
    const validEmail = ref(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
    const {
      isImagePending: isMainImagePending,
      error: errorMainImage,
      url: mainURL,
      filePath: mainFilePath,
      urlThumb: mainURLThumb,
      filePathThumb: mainFilePathThumb,
      urlMedium: mainURLMedium,
      filePathMedium: mainFilePathMedium,
      uploadImageResize: uploadMainImageResize,
      deleteImage: deleteMainImage
    } = useStorage();

    const {
      isImagePending: isLogoImagePending,
      error: errorLogoImage,
      url: logoURL,
      filePath: logoFilePath,
      urlThumb: logoURLThumb,
      filePathThumb: logoFilePathThumb,
      urlMedium: logoURLMedium,
      filePathMedium: logoFilePathMedium,
      uploadImageResize: uploadLogoImageResize,
      deleteImage: deleteLogoImage
    } = useStorage();

    const { document: offering } = getDocument("offerings", props.id);
    const { document: invoice } = getDocument("invoices", props.id);
    const { error: offeringError, updateDoc: updateOffering } = useDocument(
      "offerings",
      props.id
    );
    const { error: errorMessaging, addDoc: addMessage } = useCollection(
      "rhSupport"
    );
    const { user } = getUser();
    const formError = ref(null);
    const isPending = ref(false);
    const router = useRouter();

    //TODO add rest of field validation here
    const isPublishable = computed(
      () =>
        offering.value.description &&
        offering.value.description.length > 0 &&
        offering.value.name &&
        offering.value.name.length > 0 &&
        offering.value.issuerName &&
        offering.value.issuerName.length > 0 &&
        offering.value.contactName &&
        offering.value.contactName.length > 0 &&
        offering.value.contactEmail &&
        validEmail.value.test(offering.value.contactEmail) &&
        offering.value.issuerCity &&
        offering.value.issuerCity.length > 0 &&
        offering.value.issuerZip &&
        offering.value.issuerZip.length > 0 &&
        offering.value.issuerState &&
        offering.value.issuerState.length > 0 &&
        offering.value.issuerStreet &&
        offering.value.issuerStreet.length > 0 &&
        offering.value.contactPhone &&
        offering.value.contactPhone.length > 0 &&
        offering.value.website &&
        offering.value.website.length &&
        offering.value.stateFormation &&
        offering.value.stateFormation.length > 0 &&
        offering.value.taxId &&
        validTaxId.value.test(offering.value.taxId) &&
        offering.value.raiseAmount &&
        parseInt(offering.value.raiseAmount) > 0 &&
        offering.value.raiseAmountMin &&
        parseInt(offering.value.raiseAmountMin) > 0 &&
        offering.value.raiseAmountMax &&
        parseInt(offering.value.raiseAmountMax) > 0 &&
        offering.value.offeringCloses &&
        offering.value.companyFounded &&
        offering.value.minInvestment &&
        parseInt(offering.value.minInvestment) > 0 &&
        offering.value.maxInvestment &&
        parseInt(offering.value.maxInvestment) > 0 &&
        offering.value.offeringType &&
        (((offering.value.interestRate === 0 ||
          offering.value.interestRate > 0) &&
          offering.value.maturityDate &&
          offering.value.maturityYears &&
          offering.value.offeringType == "debt") ||
          (offering.value.offeringType == "debt" &&
            offering.value.offeringTypeDescription == "SAFE") ||
          (offering.value.pricePerShare &&
            parseInt(offering.value.pricePerShare) > 0 &&
            offering.value.offeringType == "equity")) &&
        offering.value.offeringTypeDescription
    );

    const handlePublish = async () => {
      try {
        isPending.value = true;
        formError.value = null;
        requestPublish.value = true;
        await saveDetails();
        if (offeringError.value) {
          formError.value = offeringError.value;
        } else {
          await addMessage({
            name: user.value.displayName,
            to: "hello@roundhere.co",
            from: "support@roundhere.co",
            replyTo: `${user.value.displayName} <${user.value.email}>`,
            message: {
              subject: `Request Publication for ${offering.value.id}`,
              text: `Please Publish my offering ${offering.value.id}\n\nLogged in Issuer: ${user.value.displayName}`,
              html: `Please Publish my offering ${offering.value.id}<br><br>Logged in Issuer: ${user.value.displayName}`
            },
            isLoggedIn: true,
            application: "raise"
          });
          if (errorMessaging.value) {
            formError.value = errorMessaging.value;
          } else {
            isPending.value = false;
            router.push({
              name: "Offerings"
            });
          }
        }
      } catch (err) {
        isPending.value = false;
        formError.value = err.message;
      }
    };

    const saveDetails = async () => {
      await updateOffering({
        name: offering.value.name ? offering.value.name : "",
        description: offering.value.description
          ? offering.value.description
          : "",
        issuerName: offering.value.issuerName ? offering.value.issuerName : "",
        issuerType: "company",
        regExemption: "4a6",
        offeringType: offering.value.offeringType
          ? offering.value.offeringType
          : "",
        taxId: offering.value.taxId ? offering.value.taxId : "",
        stateFormation: offering.value.stateFormation
          ? offering.value.stateFormation
          : "",
        issuerStreet: offering.value.issuerStreet
          ? offering.value.issuerStreet
          : "",
        issuerSuite: offering.value.issuerSuite
          ? offering.value.issuerSuite
          : "",
        issuerCity: offering.value.issuerCity ? offering.value.issuerCity : "",
        issuerState: offering.value.issuerState
          ? offering.value.issuerState
          : "",
        issuerZip: offering.value.issuerZip ? offering.value.issuerZip : "",
        contactName: offering.value.contactName
          ? offering.value.contactName
          : "",
        contactEmail: offering.value.contactEmail
          ? offering.value.contactEmail
          : "",
        contactPhone: offering.value.contactPhone
          ? offering.value.contactPhone
          : "",
        website: offering.value.website ? offering.value.website : "",
        linkedIn: offering.value.linkedIn ? offering.value.linkedIn : "",
        facebook: offering.value.facebook ? offering.value.facebook : "",
        instagram: offering.value.instagram ? offering.value.instagram : "",
        twitter: offering.value.twitter ? offering.value.twitter : "",
        tiktok: offering.value.tiktok ? offering.value.tiktok : "",
        minInvestment: parseInt(
          offering.value.minInvestment ? offering.value.minInvestment : 0
        ),
        maxInvestment: parseInt(
          offering.value.maxInvestment ? offering.value.maxInvestment : 0
        ),
        raiseAmount: parseInt(
          offering.value.raiseAmount ? offering.value.raiseAmount : 0
        ),
        raiseAmountMin: parseInt(
          offering.value.raiseAmountMin ? offering.value.raiseAmountMin : 0
        ),
        raiseAmountMax: parseInt(
          offering.value.raiseAmountMax ? offering.value.raiseAmountMax : 0
        ),
        preValuation: parseInt(
          offering.value.preValuation ? offering.value.preValuation : 0
        ),
        pricePerShare: parseInt(
          offering.value.pricePerShare ? offering.value.pricePerShare : 0
        ),
        interestRate:
          offering.value.interestRate === 0 || offering.value.interestRate > 0
            ? offering.value.interestRate
            : "",
        offeringTypeDescription: offering.value.offeringTypeDescription
          ? offering.value.offeringTypeDescription
          : "",
        offeringCloses: offering.value.offeringCloses
          ? offering.value.offeringCloses
          : "",
        maturityDate: offering.value.maturityDate
          ? offering.value.maturityDate
          : "",
        maturityYears: offering.value.maturityYears
          ? offering.value.maturityYears
          : 0,
        companyFounded: offering.value.companyFounded
          ? offering.value.companyFounded
          : "",
        requestPublish: requestPublish.value,
        logoFilePath: offering.value.logoFilePath
          ? offering.value.logoFilePath
          : "",
        logoURL: offering.value.logoURL ? offering.value.logoURL : "",
        logoFilePathThumb: offering.value.logoFilePathThumb
          ? offering.value.logoFilePathThumb
          : "",
        logoURLThumb: offering.value.logoURLThumb
          ? offering.value.logoURLThumb
          : "",
        logoFilePathMedium: offering.value.logoFilePathMedium
          ? offering.value.logoFilePathMedium
          : "",
        logoURLMedium: offering.value.logoURLMedium
          ? offering.value.logoURLMedium
          : "",
        mainFilePath: offering.value.mainFilePath
          ? offering.value.mainFilePath
          : "",
        mainURL: offering.value.mainURL ? offering.value.mainURL : "",

        mainFilePathThumb: offering.value.mainFilePathThumb
          ? offering.value.mainFilePathThumb
          : "",
        mainURLThumb: offering.value.mainURLThumb
          ? offering.value.mainURLThumb
          : "",
        mainFilePathMedium: offering.value.mainFilePathMedium
          ? offering.value.mainFilePathMedium
          : "",
        mainURLMedium: offering.value.mainURLMedium
          ? offering.value.mainURLMedium
          : "",
        allowAccreditedInvestors:
          typeof offering.value.allowAccreditedInvestors === "undefined"
            ? false
            : offering.value.allowAccreditedInvestors,
        allowNonAccreditedInvestors:
          typeof offering.value.allowNonAccreditedInvestors === "undefined"
            ? false
            : offering.value.allowNonAccreditedInvestors,
        allowUSInvestors:
          typeof offering.value.allowUSInvestors === "undefined"
            ? false
            : offering.value.allowUSInvestors,
        allowNonUSInvestors:
          typeof offering.value.allowNonUSInvestors === "undefined"
            ? false
            : offering.value.allowNonUSInvestors,
        tags: offering.value.tags,
        editedAt: timestamp()
      });
    };

    const handleSave = async () => {
      try {
        isPending.value = true;
        formError.value = "";
        await saveDetails();
        isPending.value = false;
        if (offeringError.value) {
          formError.value = offeringError.value;
        } else {
          formError.value = null;
          router.push({
            name: "OfferingPitch",
            params: { id: props.id }
          });
        }
      } catch (err) {
        formError.value = "System Error: " + err.message;
      }
    };

    // allowed file types
    const types = ["image/png", "image/jpeg"];

    const handleLogoChange = async e => {
      const selected = e.target.files[0];
      if (selected && types.includes(selected.type)) {
        isPending.value = true;
        //Delete the existing images so our resize doesn't break
        if (offering.value.logoFilePath) {
          await deleteLogoImage(offering.value.logoFilePath);
          console.log(`Storage Update: ${errorLogoImage.value}`);
          offering.value.logoFilePath = "";
          offering.value.logoURL = "";
        }
        if (offering.value.logoFilePathThumb) {
          await deleteLogoImage(offering.value.logoFilePathThumb);
          console.log(`Storage Update: ${errorLogoImage.value}`);
          offering.value.logoFilePathThumb = "";
          offering.value.logoURLThumb = "";
        }
        if (offering.value.logoFilePathMedium) {
          await deleteLogoImage(offering.value.logoFilePathMedium);
          console.log(`Storage Update: ${errorLogoImage.value}`);
          offering.value.logoFilePathMedium = "";
          offering.value.logoURLMedium = "";
        }
        await uploadLogoImageResize(
          "offerings/" + offering.value.id,
          "logo",
          selected
        );
        if (!errorLogoImage.value) {
          offering.value.logoFilePath = logoFilePath.value;
          offering.value.logoURL = logoURL.value;
          offering.value.logoFilePathThumb = logoFilePathThumb.value;
          offering.value.logoURLThumb = logoURLThumb.value;
          offering.value.logoFilePathMedium = logoFilePathMedium.value;
          offering.value.logoURLMedium = logoURLMedium.value;
          await saveDetails();
          if (offeringError.value) {
            errorLogoImage.value = offeringError.value;
          }
        }
        isPending.value = false;
      } else {
        errorLogoImage.value = "Please select an image file (png or jpeg)";
      }
    };

    const handleMainImageChange = async e => {
      const selected = e.target.files[0];
      if (selected && types.includes(selected.type)) {
        isPending.value = true;
        //Delete the existing images so our resize doesn't break
        if (offering.value.mainFilePath) {
          await deleteMainImage(offering.value.mainFilePath);
          console.log(`Storage Update: ${errorMainImage.value}`);
          offering.value.mainFilePath = "";
          offering.value.mainURL = "";
        }
        if (offering.value.mainFilePathThumb) {
          await deleteMainImage(offering.value.mainFilePathThumb);
          console.log(`Storage Update: ${errorMainImage.value}`);
          offering.value.mainFilePathThumb = "";
          offering.value.mainURLThumb = "";
        }
        if (offering.value.mainFilePathMedium) {
          await deleteMainImage(offering.value.mainFilePathMedium);
          console.log(`Storage Update: ${errorMainImage.value}`);
          offering.value.mainFilePathMedium = "";
          offering.value.mainURLMedium = "";
        }
        await uploadMainImageResize(
          "offerings/" + offering.value.id,
          "Main",
          selected
        );
        if (!errorMainImage.value) {
          console.log("Storage Update: Uploaded");
          offering.value.mainFilePath = mainFilePath.value;
          offering.value.mainURL = mainURL.value;
          offering.value.mainFilePathThumb = mainFilePathThumb.value;
          offering.value.mainURLThumb = mainURLThumb.value;
          offering.value.mainFilePathMedium = mainFilePathMedium.value;
          offering.value.mainURLMedium = mainURLMedium.value;
          console.log("Storage Update: New File Names");
          await saveDetails();
          if (offeringError.value) {
            errorMainImage.value = offeringError.value;
          }
          console.log("Storage Update: Saved Changes");
        }
        isPending.value = false;
      } else {
        errorMainImage.value = "Please select an image file (png or jpeg)";
      }
    };

    const deleteOffering = async () => {
      let offeringCollections = ["offerings", "offeringPitches"];

      //TODO delete teamMembers and other collection
      for (let i = 0; i < offeringCollections.length; i++) {
        const { error: deleteError, deleteDoc } = useDocument(
          offeringCollections[i],
          props.id
        );
        await deleteDoc();
        if (deleteError.value) {
          console.log(
            `Error deleting document from ${offeringCollections[i]} : ${deleteError.value}`
          );
          formError.value = deleteError.value;
        }
      }
    };

    const handleDelete = async () => {
      isPending.value = true;
      //TODO delete other image locations in other Offering Collections
      if (offering.value.logoFilePath) {
        await deleteLogoImage(offering.value.logoFilePath);
      }
      if (offering.value.mainFilePath) {
        await deleteMainImage(offering.value.mainFilePath);
      }
      if (offering.value.logoFilePathThumb) {
        await deleteLogoImage(offering.value.logoFilePathThumb);
      }
      if (offering.value.mainFilePathThumb) {
        await deleteMainImage(offering.value.mainFilePathThumb);
      }
      if (offering.value.logoFilePathMedium) {
        await deleteLogoImage(offering.value.logoFilePathMedium);
      }
      if (offering.value.mainFilePathMedium) {
        await deleteMainImage(offering.value.mainFilePathMedium);
      }
      await deleteOffering();
      isPending.value = false;
      if (!formError.value) {
        router.push({ name: "Offerings" });
      }
    };

    const validateEmail = () => {
      emailError.value = null;
      if (!validEmail.value.test(offering.value.contactEmail)) {
        emailError.value = "Invalid Email Address.";
      }
    };

    const validateTaxId = () => {
      taxIdError.value = null;
      //Issue #108 says only companies can issue offerings
      if (!validTaxId.value.test(offering.value.taxId)) {
        taxIdError.value = "Tax Id should be in the form: 99-9999999";
      }
    };

    const handleTag = async () => {
      tag.value = tag.value.toUpperCase();
      console.log(`Tag: ${tag.value}`);
      if (!offering.value.tags.includes(tag.value)) {
        tag.value = tag.value.replace(/\W/g, ""); // Removes all whitespace
        offering.value.tags.push(tag.value);
        //TODO save tag here
        await saveDetails();
        if (offeringError.value) {
          formError.value = "Error saving tags.";
        } else {
          tag.value = "";
        }
      } else {
        tag.value = "";
      }
    };

    const deleteTag = async tag => {
      console.log(`Delete this tag: ${tag}`);
      const index = offering.value.tags.indexOf(tag);
      if (index > -1) {
        offering.value.tags.splice(index, 1);
        console.log("about to save tags");
        await saveDetails();
        if (offeringError.value) {
          formError.value = "Error saving tags.";
        }
        console.log("Tags Saved");
      }
    };

    return {
      isMainImagePending,
      isLogoImagePending,
      tag,
      handleTag,
      deleteTag,
      taxIdError,
      emailError,
      selected,
      offering,
      invoice,
      handleSave,
      handleLogoChange,
      handleMainImageChange,
      handleDelete,
      handlePublish,
      formError,
      isPending,
      isPublishable,
      validateTaxId,
      validateEmail,
      errorMainImage,
      errorLogoImage
    };
  }
};
</script>

<style>
</style>